import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import BellIcon from '@mui/icons-material/Notifications';

import { getNotifications, setAsOld, setAsViewed } from '@services/notificationsService';
import { NotificationDataType } from '@query';
import { NotificationLink } from '@utils/consts/olds';
import {
  Badge,
  Box,
  formatDate,
  formatTime,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Typography
} from '@tulp';

import notificationImage from '../../../images/default_notification.png';

export function IconBellButton() {
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [notificationsPages, setNotificationsPages] = useState(0);
  const [notificationsPage, setNotificationsPage] = useState<number | undefined>(0);
  const [notifications, setNotifications] = useState<NotificationDataType[]>([]);

  function handleOpenNotificationMenu() {
    setAsOld()
      .then(() => {
        setNotificationsCount(0);
      })
      .catch(() => {});
  }

  function getNotificationsList(pageNumber?: number) {
    setNotificationsPage(pageNumber);
    getNotifications({ page: pageNumber })
      .then((result) => {
        setNotifications([...notifications, ...result.data.notifications.elements]);
        setNotificationsCount(result.data.newNotificationsCount);
        setNotificationsPages(result.data.notifications.totalPages);
      })
      .catch(() => {
        // TODO: Bad practice, we should handle this error
      });
  }

  function markAsViewed(notification: NotificationDataType) {
    return setAsViewed({ notificationId: notification.id })
      .then(() => {
        notifications.map((notif) => {
          if (notif.date === notification.date) notif.viewed = true;
          return notif;
        });
        setNotifications([...notifications]);
      })
      .catch(() => {});
  }

  function clickToNavigate(link: string) {
    if (link) window.location.href = link;
  }

  // TODO: Refactor this to use RQ
  // Polling for notifications
  // User refetchInterval to poll for notifications
  useEffect(() => {
    getNotificationsList();
    const interval = setInterval(() => {
      getNotificationsList();
    }, 60000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Popover>
      <PopoverTrigger asChild onClick={handleOpenNotificationMenu}>
        <IconButton>
          <Badge badgeContent={notificationsCount} color='info'>
            <BellIcon />
          </Badge>
        </IconButton>
      </PopoverTrigger>
      <PopoverContent>
        <Paper>
          <InfiniteScroll
            pageStart={0}
            loadMore={(page) => {
              if (notificationsPages < (notificationsPage ?? 0)) getNotificationsList(page);
            }}
            hasMore={true}
            useWindow={false}
          >
            {notifications.length === 0 && (
              <ListItem>
                <ListItemText>No notifications found</ListItemText>
              </ListItem>
            )}
            {notifications.map((notification, index) => (
              <ListItem disablePadding key={notification.date + index}>
                <ListItemButton
                  href={notification.redirectUrl}
                  onClick={() => {
                    markAsViewed(notification)
                      .then(() => {
                        if (notification.notificationCode) {
                          clickToNavigate(NotificationLink[notification.notificationCode]);
                        }
                      })
                      .catch(() => {});
                  }}
                >
                  <ListItemIcon>
                    {notification.image ? (
                      <Box width={40}>
                        <img src={notification.image} alt='Profile' style={{ width: '100%' }} />
                      </Box>
                    ) : (
                      <Box width={40}>
                        <img src={notificationImage} alt='Profile' style={{ width: '100%' }} />
                      </Box>
                    )}
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant='body1' paragraph>
                      <b>{notification.message}</b>
                    </Typography>
                    <Stack direction='row' spacing={3}>
                      <Box>
                        <Typography>Date</Typography>
                        <Typography>{formatDate(notification.date)}</Typography>
                      </Box>
                      <Box>
                        <Typography>Time</Typography>
                        <Typography>{formatTime(notification.date)}</Typography>
                      </Box>
                    </Stack>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
          </InfiniteScroll>
        </Paper>
      </PopoverContent>
    </Popover>
  );
}
