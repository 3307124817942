import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import ChatIcon from '@mui/icons-material/ChatBubbleOutlineRounded';
import { NavigationRoute } from '@routes';
import { useQueryClient } from '@tanstack/react-query';
import { useGate } from 'statsig-react';

import { AppState } from '@chat-app/store';

import { useGetAuthenticatedUser } from '@query';
import { logoutCallback } from '@utils/auth';
import {
  AppBar as TulpAppBar,
  Avatar,
  Badge,
  Box,
  ButtonLink,
  Container,
  DropdownButton,
  IconButton,
  IconButtonLink,
  Menu,
  MenuIcon,
  MenuItem,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
  useIsButtonLinkActive,
  useMediaQuery,
  useTheme
} from '@tulp';

import logo from '../../images/app_bar_logo.png';
import smallLogo from '../../images/small_logo.svg';
import { IconBellButton } from './components/icon-bell-button';

const profileMenu = [
  {
    label: 'My Profile',
    to: '/profile'
  },
  {
    label: 'Settings',
    to: '/settings'
  }
];

export type AppBarProps = {
  routes: NavigationRoute[];
  onDrawerToggle: () => void;
};

export function AppBar(props: Readonly<AppBarProps>) {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const { value: isFullCalendarFeatureEnabled } = useGate('full_calendar');

  const checkActive = useIsButtonLinkActive();

  const theme = useTheme();
  const mediaQueryUpSm = useMediaQuery(theme.breakpoints.up('md'));

  const unreadMessages = useSelector((state: AppState) => state.unreadMessages);
  const unreadMessagesCount = Object.values(unreadMessages ?? []).reduce((a, b) => a + b, 0);

  const queryClient = useQueryClient();
  const { data: authenticatedUser } = useGetAuthenticatedUser();

  function handleOpenUserMenu(event: React.MouseEvent<HTMLElement>) {
    setAnchorElUser(event.currentTarget);
  }

  function handleCloseUserMenu() {
    setAnchorElUser(null);
  }

  async function handleLogout() {
    logoutCallback(queryClient, '/login');
  }

  useEffect(() => {
    if (unreadMessagesCount > 0) {
      if (document.title.includes('(')) {
        document.title = `(${unreadMessagesCount}) ${document.title.replace(/\(\d+\)\s*/, '')}`;
      } else {
        document.title = `(${unreadMessagesCount}) ${document.title}`;
      }
    }
  }, [unreadMessagesCount]);

  return (
    <>
      <TulpAppBar variant='outlined' elevation={0} position='fixed' color='inherit' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Container maxWidth='lg'>
          <Toolbar disableGutters>
            <IconButton color='inherit' aria-label='open drawer' edge='start' sx={{ mr: 2, display: { md: 'none' } }} onClick={props.onDrawerToggle}>
              <MenuIcon />
            </IconButton>
            <Box display='flex' alignItems='center' flexGrow={1} component={Link} to='/home'>
              <Box component='img' sx={{ width: 200, display: { xs: 'none', sm: 'initial' } }} src={logo} alt='SalesHookup logo' />
              <Box component='img' sx={{ display: { xs: 'initial', sm: 'none' } }} src={smallLogo} alt='SalesHookup logo' />
            </Box>
            <Stack direction='row' spacing={{ xs: 0, md: 3 }}>
              {mediaQueryUpSm && (
                <>
                  {props.routes.map((route) =>
                    route.children ? (
                      <DropdownButton key={route.id} title={route.label}>
                        {route.children
                          .filter((route) => route.allowedRoles.some((role) => authenticatedUser.roles.includes(role)))
                          .map((child) => (
                            <MenuItem selected={checkActive(child?.to?.toString() ?? '')} key={child.id} component={Link} to={child.to ?? ''}>
                              {child.label}
                            </MenuItem>
                          ))}
                      </DropdownButton>
                    ) : route.label ? (
                      <ButtonLink key={route.id} to={route.to ?? ''}>
                        <Typography variant='subtitle4'>{route.label}</Typography>
                      </ButtonLink>
                    ) : null
                  )}
                </>
              )}
              {isFullCalendarFeatureEnabled && (
                <IconButtonLink to='/calendar'>
                  <CalendarMonthRoundedIcon />
                </IconButtonLink>
              )}
              <IconButtonLink to='/chat'>
                <Badge badgeContent={unreadMessagesCount}>{<ChatIcon />}</Badge>
              </IconButtonLink>
              <IconBellButton />
            </Stack>
            <Tooltip title='Open menu'>
              <IconButton onClick={handleOpenUserMenu}>
                <Avatar alt={authenticatedUser.fullName} src={authenticatedUser.imageUrl ?? ''}>
                  {authenticatedUser.fullName[0]}
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id='menu-appbar'
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {profileMenu.map((menuItem) => (
                <MenuItem component={Link} key={menuItem.label} to={menuItem.to} onClick={handleCloseUserMenu}>
                  <Typography textAlign='center'>{menuItem.label}</Typography>
                </MenuItem>
              ))}
              <MenuItem onClick={handleLogout}>
                <Typography textAlign='center'>Log out</Typography>
              </MenuItem>
            </Menu>
          </Toolbar>
        </Container>
      </TulpAppBar>
      {/* <Paper sx={{ backgroundColor: teal[50], position: 'fixed', top: 64, width: '100%' }}>
        <Container maxWidth='lg'>
          <Toolbar variant='dense'>
            <VerifiedRoundedIcon sx={{ mr: 2, color: teal[600] }} />
            <Typography>
              <span style={{ fontWeight: 900 }}>SALES</span>
              <span style={{ fontWeight: 100 }}>HOOKUP is made possible with the help of our amazing sponsor</span>,{' '}
              <span style={{ fontWeight: 900, color: 'rgb(1, 119, 55)' }}>pipedrive</span>.{' '}
              <span style={{ fontWeight: 100 }}>
                Go to <Link to='/'>Member Perks</Link> for you exclusive offer.
              </span>
            </Typography>
          </Toolbar>
        </Container>
      </Paper> */}
    </>
  );
}
