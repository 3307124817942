import { QueryFunctionContext } from '@tanstack/react-query';
import axios from 'axios';

import { request } from '@request';
import { DateTime, getDateTime, UnknownObject } from '@tulp';

import {
  CancelGroupHookupParamsType,
  CreateGroupHookupReqBodyType,
  GetGroupHookupQueryParamsType,
  GroupHookupCategoryDetailsType,
  GroupHookupDetailsType,
  GroupHookupItemListType,
  PaginatedDataType,
  QueryParamsWithSignal,
  RejectGroupHookupParamsType,
  UpdateGroupHookupReqBodyType
} from '../types';
import { URL_API_BASE } from '.';

// 5 minutes before the meeting starts
export const MinutesBeforeJoinIsAvailable = 5;

export async function createGroupHookup(data: CreateGroupHookupReqBodyType) {
  const res = await request.post(`${URL_API_BASE}/group-hookup`, { data });
  return res.data as GroupHookupDetailsType;
}

export async function updateGroupHookup(uuid: string, data: UpdateGroupHookupReqBodyType) {
  const res = await request.put(`${URL_API_BASE}/group-hookup/${uuid}`, { data });
  return res.data as GroupHookupDetailsType;
}

export async function updateGroupHookupImage(uuid: string, file?: FormData) {
  const res = await axios.put(`${URL_API_BASE}/group-hookup/${uuid}/image`, file);
  return res.data as GroupHookupDetailsType;
}

export async function attendGroupHookup(uuid: string) {
  const res = await request.post(`${URL_API_BASE}/group-hookup/attend?uuid=${uuid}`);
  return res.data as UnknownObject;
}

export function generateExtraPropsForGroupHookupDetails(gh: GroupHookupDetailsType) {
  const canUserJoinDateCondition = gh
    ? getDateTime(gh.startDate).minus({ minutes: MinutesBeforeJoinIsAvailable }).toMillis() <= DateTime.now().setZone('utc').toMillis()
    : false;
  const canUserJoin = import.meta.env.VITE_ENV === 'prod' ? canUserJoinDateCondition : true;
  const participantsUUIDs = gh.participants.map((p) => p.uuid);
  const hostParticipant = gh.participants.find((p) => p.uuid === gh.host);
  const userNeedsToAttendFirst = (authenticatedUserUUID: string) => !participantsUUIDs.includes(authenticatedUserUUID);
  const isHost = (authenticatedUserUUID: string) => gh.host === authenticatedUserUUID;

  return { ...gh, canUserJoin, participantsUUIDs, hostParticipant, userNeedsToAttendFirst, isHost } as GroupHookupDetailsType;
}

export async function getPublicGroupHookupDetails(args?: QueryParamsWithSignal<GetGroupHookupQueryParamsType>) {
  const res = await request.get(`${URL_API_BASE}/group-hookup/public?uuid=${args?.params?.uuid}`, { signal: args?.signal });
  return generateExtraPropsForGroupHookupDetails(res.data as GroupHookupDetailsType);
}

export async function getGroupHookupDetails(args?: QueryParamsWithSignal<GetGroupHookupQueryParamsType>) {
  const res = await request.get(`${URL_API_BASE}/group-hookup/${args?.params?.uuid}`, { signal: args?.signal });
  return generateExtraPropsForGroupHookupDetails(res.data as GroupHookupDetailsType);
}

export async function getGroupHookupCategories({ signal }: QueryFunctionContext) {
  const res = await request.get(`${URL_API_BASE}/category`, { signal });
  return res.data as GroupHookupCategoryDetailsType[];
}

export async function getAllGroupHookups(args?: QueryFunctionContext | undefined) {
  const res = await request.get(`${URL_API_BASE}/group-hookup/all`, { signal: args?.signal });
  return res.data as PaginatedDataType<GroupHookupItemListType>;
}

export async function getHighlightedGroupHookups(args?: QueryFunctionContext | undefined) {
  const res = await request.get(`${URL_API_BASE}/group-hookup/highlighted`, { signal: args?.signal });
  return res.data as PaginatedDataType<GroupHookupItemListType>;
}

export async function cancelGroupHookup(params: CancelGroupHookupParamsType) {
  const res = await request.put(`${URL_API_BASE}/group-hookup/${params.uuid}/cancel?reason=${params.reason}`);
  return res.data as string;
}

// Admin side

export async function approveGroupHookup(uuid: string) {
  const res = await request.put(`${URL_API_BASE}/hookup-admin/group-hookups/${uuid}/approve`);
  return res.data as string;
}

export async function rejectGroupHookup(params: RejectGroupHookupParamsType) {
  const res = await request.put(`${URL_API_BASE}/hookup-admin/group-hookups/${params.uuid}/reject?reason=${params.reason}`);
  return res.data as string;
}

export async function highlightGroupHookup(uuid: string) {
  const res = await request.put(`${URL_API_BASE}/hookup-admin/group-hookup/${uuid}/highlight`);
  return res.data as string;
}

export async function unHighlightGroupHookup(uuid: string) {
  const res = await request.put(`${URL_API_BASE}/hookup-admin/group-hookup/${uuid}/unhighlight`);
  return res.data as string;
}

export async function leaveGroupHookup(uuid: string) {
  const res = await request.put(`${URL_API_BASE}/group-hookup/leave/${uuid}`);
  return res.data as UnknownObject;
}
