import * as z from 'zod';

import { getMsgMax, getMsgMin } from './common-messages';

const GroupHookupTitle = z.string().min(3, getMsgMin('Title', 3)).max(70, getMsgMax('Title', 70));
const GroupHookupLongText = z.string().min(10, getMsgMin('Description', 10)).max(1000, getMsgMax('Description', 1000)).optional();

export const CreateGroupHookupValidationSchema = z.object({
  title: GroupHookupTitle,
  description: GroupHookupLongText,
  whoShouldJoin: GroupHookupLongText.optional(),
  whyShouldJoin: GroupHookupLongText.optional(),
  whatWillDiscuss: GroupHookupLongText.optional(),
  category: z.string(),
  startDate: z.string(),
  duration: z.number(),
  // This feature is hidden behind a feature flag, so it's optional
  tags: z.array(z.string()).optional(),
  // Only used for "big events"
  maxParticipants: z.number().optional(),
  image: z.string().optional()
});

export const UpdateGroupHookupValidationSchema = z.object({
  title: GroupHookupTitle,
  description: GroupHookupLongText,
  whoShouldJoin: GroupHookupLongText.optional(),
  whyShouldJoin: GroupHookupLongText.optional(),
  whatWillDiscuss: GroupHookupLongText.optional(),
  sponsored: z.boolean().optional()
});
