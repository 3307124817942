import { forwardRef, useEffect, useRef, useState } from 'react';
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import { TRANSFORMERS } from '@lexical/markdown';
import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { EditorRefPlugin } from '@lexical/react/LexicalEditorRefPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { $createParagraphNode, $createTextNode, $getRoot, type EditorState, type LexicalEditor } from 'lexical';
import { useGate } from 'statsig-react';

import { useTheme } from '@tulp';

import './style.css';
import Nodes from './nodes';
import LexicalAutoLinkPlugin from './plugins/AutoLinkPlugin/index';
import CodeHighlightPlugin from './plugins/CodeHighlightPlugin';
import EmojiPickerPlugin from './plugins/EmojiPickerPlugin';
import EmojisPlugin from './plugins/EmojisPlugin';
import FloatingLinkEditorPlugin from './plugins/FloatingLinkEditorPlugin';
import FloatingTextFormatToolbarPlugin from './plugins/FloatingTextFormatToolbarPlugin';
import LinkPlugin from './plugins/LinkPlugin';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import EditorTheme from './themes/EditorTheme';
import ContentEditable from './ui/ContentEditable';
import Placeholder from './ui/Placeholder';
// import CodeActionMenuPlugin from './plugins/CodeActionMenuPlugin'

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.

const DefaultValueSetter = ({ defaultValue }: Pick<RichTextEditorPropsType, 'defaultValue'>) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.update(() => {
      const root = $getRoot();
      root.clear();

      if (defaultValue) {
        const isHTML = /<\/?[a-z][\s\S]*>/i.test(defaultValue);
        if (isHTML) {
          const parser = new DOMParser();
          const doc = parser.parseFromString(defaultValue, 'text/html');
          const nodes = $generateNodesFromDOM(editor, doc);
          root.append(...nodes);
        } else {
          const paragraph = $createParagraphNode();
          const textNode = $createTextNode(defaultValue);
          paragraph.append(textNode);
          root.append(paragraph);
        }
      }
    });
  }, [editor, defaultValue]);

  return null;
};

function onError(error: unknown) {
  console.error(error);
}

export type RichTextEditorPropsType = {
  placeholder: string;
  condensed?: boolean;
  defaultValue?: string;
  error?: string;
  onChange?: (editorState: EditorState, editor: LexicalEditor, tags: Set<string>) => void;
  onChangeToHtmlValue?: (markdownValue: string) => void;
};

//https://github.com/facebook/lexical/tree/main/packages/lexical-playground/src
export const RichTextEditor = forwardRef<LexicalEditor, RichTextEditorPropsType>((props, ref) => {
  const [isLinkEditMode, setIsLinkEditMode] = useState<boolean>(false);
  const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null);
  const editorStateRef = useRef<EditorState>();

  const { value: isEmojiPluginFeatureEnabled } = useGate('rich_text_editor_emoji_plugin');

  const theme = useTheme();

  function handleOnChange(editorState: EditorState, editor: LexicalEditor, tags: Set<string>) {
    editorStateRef.current = editorState;

    props.onChange?.(editorState, editor, tags);
    editorState.read(() => {
      props.onChangeToHtmlValue?.($generateHtmlFromNodes(editor));
    });
  }

  function onRef(_floatingAnchorElem: HTMLDivElement) {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  }

  const placeholder = <Placeholder>{props.placeholder}</Placeholder>;
  const initialConfig = {
    namespace: 'RichTextEditor',
    theme: EditorTheme,
    onError,
    nodes: [...Nodes]
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <DefaultValueSetter defaultValue={props.defaultValue} />
      <div className='editor-shell' style={props.error ? { border: `1px solid ${theme.palette.error.main}`, color: theme.palette.error.main } : {}}>
        <ToolbarPlugin condensed={props.condensed} />
        <div className='editor-container'>
          <LexicalAutoLinkPlugin />
          <CheckListPlugin />
          <RichTextPlugin
            contentEditable={
              <div className={`editor-scroller ${props.condensed ? 'condensed' : ''}`}>
                <div className='editor' ref={onRef}>
                  <ContentEditable />
                </div>
              </div>
            }
            placeholder={placeholder}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <OnChangePlugin onChange={handleOnChange} />
          <HistoryPlugin />
          <ListPlugin />
          <CodeHighlightPlugin />
          <LinkPlugin />
          {isEmojiPluginFeatureEnabled ? (
            <>
              <EmojiPickerPlugin />
              <EmojisPlugin />
            </>
          ) : null}
          <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
          {floatingAnchorElem && (
            <>
              <FloatingLinkEditorPlugin anchorElem={floatingAnchorElem} isLinkEditMode={isLinkEditMode} setIsLinkEditMode={setIsLinkEditMode} />
              <FloatingTextFormatToolbarPlugin anchorElem={floatingAnchorElem} />
            </>
          )}
          <ClearEditorPlugin />
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <EditorRefPlugin editorRef={ref} />
        </div>
      </div>
    </LexicalComposer>
  );
});

RichTextEditor.displayName = 'RichTextEditor';
