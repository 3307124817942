import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';

import { Button, ButtonProps } from '../Button';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

export type InputFileUploadPropsType = {
  children: React.ReactNode;
  variant?: ButtonProps['variant'];
  sx?: ButtonProps['sx'];
  size?: ButtonProps['size'];
  color?: ButtonProps['color'];
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export function InputFileUpload(props: Readonly<InputFileUploadPropsType>) {
  return (
    <Button
      component='label'
      size={props.size}
      color={props.color}
      role={undefined}
      variant={props.variant}
      tabIndex={-1}
      startIcon={<CloudUploadIcon />}
      sx={props.sx}
    >
      {props.children}
      <VisuallyHiddenInput type='file' onChange={props.onChange} />
    </Button>
  );
}
